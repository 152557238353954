import React from "react";
import "./Use.css";

function Block(props) {
  return (
    <div
      style={{ marginTop: 30 }}
      className={props.idx % 2 === 0 ? "align-left" : "align-right"}
    >
      <div className="father">
        <h1
          style={{
            fontFamily:
              "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans'",
            fontWeight: 500,
            color: "#924F49",
            order: props.idx % 2 === 0 ? 0 : 2,
            paddingRight: 20,
            paddingLeft: 20,
          }}
        >
          {props.id}
        </h1>
        {props.id === 1 ? (
          <div
            className="div"
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            <a
              href="/chat"
              style={{
                backgroundColor: "transparent",
                color: "#195476",
                fontWeight: 300,
              }}
            >
              <u>{props.message}</u>
            </a>
            <a
              href="./chat"
              style={{
                backgroundColor: "transparent",
              }}
            >
              <img
                src="./sendButton.png"
                alt="go to chat"
                style={{ height: 25 }}
              />
            </a>
          </div>
        ) : (
          <div className="div">{props.message}</div>
        )}
      </div>
    </div>
  );
}

export default Block;
