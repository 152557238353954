import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "./Chat.css";
import Header from "./NavHead";
import Footer from "./footer";

function Chat() {
  const [isInputted, setInputted] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [token, setToken] = useState(false);
  // const [message, setMessage] = useState("");
  const [isWindow, setWindow] = useState(
    window.matchMedia("(max-height: 608px)").matches
  );
  const inputRef = useRef(null);
  const captchaRef = useRef(null);

  const [inputAnswer, setInput] = useState("");

  const [array, setArray] = useState([]);

  function handleChange(event) {
    setInputted(true);
    setInput(event.target.value);
  }

  function handleExampleOne() {
    setInput("What is hepatitis B?");
    setInputted(true);
  }

  function handleExampleTwo() {
    setInput("HBV");
    setInputted(true);
  }

  function handleExampleThree() {
    setInput("What's the best way to prevent me from getting hepatitis?");
    setInputted(true);
  }

  // chatbox questions and answers array
  function addArray(input, resp) {
    const updatedArray = [
      ...array,
      { id: Math.round(Math.random() * 999), question: input, response: resp },
    ];
    setArray(updatedArray);
  }

  async function getResponse(input) {
    try {
      const response = await axios.post(
        // "http://localhost:3000/api.chathep/run",
        "/api/api.chathep.chain/run", // /api/api.chathep.chain/run
        {
          query: inputAnswer,
        }
      );
      // console.log(response.data.output);
      addArray(input, response.data.output);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  // on submit
  async function handleTap(event) {
    event.preventDefault();
    // const captchaValue = captchaRef.current.getValue();
    // console.log(captchaValue);
    // captchaRef.current.reset();
    if (token === false) {
      alert("Please check the 'I'm not a robot' box.");
      // setMessage("Please check the 'I'm not a robot' box.");
    } // else {
    //   setMessage("");
    // }
    if (!inputAnswer || token === false) {
      return;
    }
    addArray(inputAnswer, "...loading...");
    setLoading(true);
    getResponse(inputAnswer);
    setInput("");
    // setToken("");
  }

  async function verifyCall(recaptchaToken) {
    try {
      const response = await axios.post(
        // "http://localhost:3000/verify-recaptcha", // http://localhost:3000/api/recaptcha-response https://www.google.com/recaptcha/api/siteverify
        "/api/verify-recaptcha", // /api/verify-recaptcha // http://localhost:3000/api/recaptcha-response https://www.google.com/recaptcha/api/siteverify
        {
          // secret: process.env.REACT_APP_SECRET_KEY,
          secret: window.env.sitesecret, // window.env.sitesecret
          response: recaptchaToken,
        }
      );
      // console.log(response.data.response_data.success);
      setToken(response.data.response_data.success);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    inputRef.current.focus();

    function handleResize() {
      window.innerHeight < 608 ? setWindow(true) : setWindow(false);
    }

    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <div
      style={{
        backgroundColor: "rgb(243, 243, 243)",
        display: "flex",
        flexDirection: "column",
        height: "107%",
      }}
    >
      <Header chat="white" use="#331F20" resources="#331F20" one="650" />
      <div
        style={{
          position: "relative",
          height: "100%",
          backgroundColor: "transparent",
          paddingTop: 20,
        }}
      >
        <div
          className="chatbox"
          style={{
            bottom: token ? 100 : 210,
            color: "black",
            backgroundColor: "#eeeeee",
            overflow: "auto",
          }}
        >
          {isInputted && (
            <div>
              {array.map((line) => (
                <div key={line.id}>
                  <p className="chatP">
                    <strong>Q: </strong>
                    {line.question}
                  </p>
                  <p className="chatP">
                    <strong>A: </strong>
                    {line.response}
                  </p>
                  <div
                    className="thick"
                    style={{
                      backgroundColor: "rgb(53, 104, 146)",
                      height: 1,
                    }}
                  />
                </div>
              ))}
            </div>
          )}

          {/* example questions */}
          {!isInputted && (
            <div
              className="examples"
              style={{
                overflowY:
                  window.innerHeight < 608 || isWindow ? "scroll" : "auto",
                height:
                  window.innerHeight < 608 || isWindow ? "100%" : "fit-content",
                paddingRight: (window.innerHeight < 608 || isWindow) && 5,
                overflowX: "hidden",
                top: window.innerHeight < 608 || isWindow ? "50%" : "45%",
              }}
            >
              <h2
                style={{
                  color: "#49B7A8",
                  fontWeight: 300,
                  fontFamily:
                    "Inter, system-ui, Avenir, Helvetica, Arial, sans-serif",
                }}
              >
                Examples:
              </h2>
              {/* <div className="thick" style={{marginTop: -20, marginBottom: 5, width:  "30%"}} /> */}
              <button onClick={handleExampleOne} className="questions">
                <em>"What is hepatitis B?"</em>
              </button>
              <button onClick={handleExampleTwo} className="questions">
                <em>"HBV"</em>
              </button>
              <button onClick={handleExampleThree} className="questions">
                <em>
                  "What's the best way to prevent me from getting hepatitis?"
                </em>
              </button>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            bottom: 10,
            left: 10,
          }}
        >
          {/* warning above recaptcha */}
          {token === false && (
            <div
              style={{
                position: "relative",
                // zIndex: 999,
                marginLeft: 10,
                marginBottom: 100,
              }}
            >
              {/* <h3
                style={{
                  color: "red",
                  height: 5,
                  width: "auto",
                  backgroundColor: "transparent",
                  marginBottom: 10,
                }}
              >
                {message}
              </h3> */}
              <ReCAPTCHA
                sitekey={window.env.sitekey} // window.env.sitekey
                className="g-recaptcha"
                ref={captchaRef}
                size="normal"
                render="explicit"
                onChange={verifyCall}
                theme={"light"}
              />
            </div>
          )}
          {/* input & send */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "absolute",
              bottom: 0,
              marginTop: 10,
              marginBottom: 10,
              width: "100%",
            }}
          >
            <form htmlFor="question" onSubmit={handleTap}>
              <input
                id="question"
                disabled={isLoading}
                onChange={handleChange}
                ref={inputRef}
                type="text"
                placeholder="Ask a question..."
                value={inputAnswer}
                style={{
                  backgroundColor: "lightgray",
                  color: "black",
                }}
              />
            </form>
            <button
              style={{
                // paddingLeft: inputAnswer !== "" ? "20px" : "0",
                backgroundColor: inputAnswer !== "" ? "#49B7A8" : "transparent",
              }}
              // onMouseOver={handleMouseOver}
              // onMouseOut={handleMouseOut}
              className="button"
              onClick={handleTap}
            >
              <img
                className="sender"
                style={{
                  height: 20,
                  marginTop: 4,
                  paddingLeft: 7,
                  paddingRight: 7,
                }}
                alt="send"
                src={
                  inputAnswer !== "" ? "./sendButton.png" : "./sendButton_2.png"
                }
              />
            </button>
          </div>
        </div>
      </div>
      <footer>
        <p className="footer" style={{ marginRight: 30 }}>
          chatHep cannot replace a medical professional. For treatment or
          medical advice, please contact your physician.{" "}
        </p>
      </footer>
      <Footer />
    </div>
  );
}

export default Chat;
