import { BrowserRouter, Routes, Route} from "react-router-dom";
import Layout from "./Layout";
import Home from "./Home";
import Use from "./Use";
import Chat from "./Chat";
import Resources from "./Resources";

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="use" element={<Use />} />
                    <Route path="chat" element={<Chat />} />
                    <Route path="resources" element={<Resources />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
