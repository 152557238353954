import React, { useState } from "react";
import "./NavHead2.css";

function CollapsibleHeader(props) {
  const [isMousedOver, setMouseOver] = useState(false);
  const [isMousedOverTwo, setMouseOverTwo] = useState(false);
  const [isMousedOverThree, setMouseOverThree] = useState(false);
  const [isMousedOverFour, setMouseOverFour] = useState(false);
  const [isClicked, setClicked] = useState(false);

  function handleMouseOver() {
    setMouseOver(true);
  }

  function handleMouseOut() {
    setMouseOver(false);
  }

  function handleMouseOverTwo() {
    setMouseOverTwo(true);
  }

  function handleMouseOutTwo() {
    setMouseOverTwo(false);
  }

  function handleMouseOverThree() {
    setMouseOverThree(true);
  }

  function handleMouseOutThree() {
    setMouseOverThree(false);
  }

  function handleClick() {
    isClicked ? setClicked(false) : setClicked(true);
  }

  function handleHoverOver() {
    setMouseOverFour(true);
  }

  function handleHoverOut() {
    setMouseOverFour(false);
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "rgb(243, 243, 243)",
        }}
      >
        <button
          onMouseOver={handleHoverOver}
          onMouseOut={handleHoverOut}
          onClick={handleClick}
          style={{
            backgroundColor: isClicked ? "rgb(232, 232, 232)" : "transparent", // #B07276 #E68273
            color: isMousedOverFour ? "white" : "#331F20",
          }}
          className="collapsible"
        >
          <img src="./menu.png" alt="menu" style={{ height: 30 }} />
        </button>
        <a href="/">
          <img
            style={{
              border: 0,
              marginLeft: 10,
              marginTop: 10,
              width: 170,
              height: "auto",
            }}
            alt="chatHep logo"
            src="./chatHep_logo2-1.png"
          />
        </a>
      </div>
      {/* {isClicked ?  */}
      <div
        className="content"
        style={{
          zIndex: 99999945,
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          height: isClicked ? "200px" : 0,
          width: "100vw",
          transition: "0.5s",
        }}
      >
        <a style={{ display: "flex", flexDirection: "column" }} href="/chat">
          <button
            style={{
              color: isMousedOver && "#919191", // color: isMousedOver ? "white" : props.chat,
              fontWeight: props.one,
            }}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            Chat
          </button>
        </a>
        <a href="/use">
          <button
            style={{
              color: isMousedOverTwo && "#919191",
              fontWeight: props.two,
            }}
            onMouseOver={handleMouseOverTwo}
            onMouseOut={handleMouseOutTwo}
          >
            How to Use
          </button>
        </a>
        <a href="/resources">
          <button
            style={{
              color: isMousedOverThree && "#919191",
              fontWeight: props.three,
            }}
            onMouseOver={handleMouseOverThree}
            onMouseOut={handleMouseOutThree}
            // onClick={handleClick}
          >
            Resources
          </button>
        </a>
      </div>
      {/* : null} */}
    </div>
  );
}

export default CollapsibleHeader;
