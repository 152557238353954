import React, { useState, useEffect } from "react";
import "./NavHead.css";
import CollapsibleHeader from "./NavHead2";

function Header(props) {
  const [isMousedOver, setMouseOver] = useState(false);
  const [isMousedOverTwo, setMouseOverTwo] = useState(false);
  const [isMousedOverThree, setMouseOverThree] = useState(false);
  const [isWindow, setWindow] = useState(
    window.matchMedia("(max-width: 673px)").matches
  );

  function handleMouseOver() {
    setMouseOver(true);
  }

  function handleMouseOut() {
    setMouseOver(false);
  }

  function handleMouseOverTwo() {
    setMouseOverTwo(true);
  }

  function handleMouseOutTwo() {
    setMouseOverTwo(false);
  }

  function handleMouseOverThree() {
    setMouseOverThree(true);
  }

  function handleMouseOutThree() {
    setMouseOverThree(false);
  }

  useEffect(() => {
    function handleResize() {
      window.innerWidth < 673 ? setWindow(true) : setWindow(false);
    }

    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <div>
      <div className="header">
        {isWindow ? (
          <CollapsibleHeader
            chat={props.chat}
            use={props.use}
            resources={props.resources}
            one={props.one}
            two={props.two}
            three={props.three}
          />
        ) : (
          <table cellSpacing="5px">
            <tbody>
              <tr>
                <td>
                  <a href="/">
                    <img
                      style={{
                        border: 0,
                        marginLeft: 0,
                        width: 170,
                        height: "auto",
                      }}
                      alt="chatHep logo"
                      src="./chatHep_logo2-1.png"
                    />
                  </a>
                </td>
                <td
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <a
                    style={{ display: "flex", flexDirection: "column" }}
                    href="/chat"
                  >
                    <button
                      className="contain"
                      style={{
                        color: isMousedOver && "#919191", // isMousedOver ? "#919191" : props.chat
                        fontWeight: props.one,
                        // backgroundColor: props.chat,
                      }}
                      onMouseOver={handleMouseOver}
                      onMouseOut={handleMouseOut}
                    >
                      Chat
                    </button>
                    <div
                      className="animatedLine"
                      style={{
                        height: 5,
                        backgroundColor: "#AAE3D9",
                        marginTop: -10,
                        marginLeft: 10,
                        transition: "0.2s",
                        width: isMousedOver ? 150 : 0,
                        borderRadius: 30,
                      }}
                    />
                  </a>
                  <a href="/use">
                    <button
                      className="contain"
                      style={{
                        color: isMousedOverTwo && "#919191", // isMousedOverTwo ? "white" : props.use
                        fontWeight: props.two,
                      }}
                      onMouseOver={handleMouseOverTwo}
                      onMouseOut={handleMouseOutTwo}
                    >
                      How to Use
                    </button>
                    <div
                      className="animatedLine"
                      style={{
                        height: 5,
                        backgroundColor: "#AAE3D9",
                        marginTop: -10,
                        marginLeft: 10,
                        transition: "0.2s",
                        width: isMousedOverTwo ? 150 : 0,
                        borderRadius: 30,
                      }}
                    />
                  </a>
                  <a href="/resources">
                    <button
                      className="contain"
                      style={{
                        color: isMousedOverThree && "#919191", // color: isMousedOverThree ? "white" : props.resources
                        fontWeight: props.three,
                      }}
                      onMouseOver={handleMouseOverThree}
                      onMouseOut={handleMouseOutThree}
                      // onClick={handleClick}
                    >
                      Resources
                    </button>
                    <div
                      className="animatedLine"
                      style={{
                        display: isWindow && "none",
                        height: 5,
                        backgroundColor: "#AAE3D9",
                        marginTop: -10,
                        marginLeft: 10,
                        transition: "0.2s",
                        width: isMousedOverThree ? 150 : 0,
                        borderRadius: 30,
                      }}
                    />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>

      {/* {isWindow || window.innerWidth < 673 ?  : null} */}
    </div>
  );
}

export default Header;
