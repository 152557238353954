import React, { useState, useEffect } from "react";
import "./Home.css";
import Header from "./NavHead";
import { LeftBox, RightBox } from "./InfoBox";
import { LeftBoxTwo, RightBoxTwo } from "./InfoBox2";
import Footer from "./footer";

function Home() {
  const [isMouseOver, setMouseOver] = useState(false);
  const [isWindow, setWindow] = useState(
    window.matchMedia("(max-width: 613px)").matches
  );

  function handleMouseOver() {
    setMouseOver(true);
  }

  function handleMouseOut() {
    setMouseOver(false);
  }

  useEffect(() => {
    function handleResize() {
      window.innerWidth < 613 ? setWindow(true) : setWindow(false);
    }

    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <div
      style={{
        backgroundColor: "rgb(243, 243, 243)",
      }}
    >
      <Header chat="#331F20" use="#331F20" resources="#331F20" />
      <div style={{ position: "relative", textAlign: "center" }}>
        <img
          src="./banner-light3.png"
          alt="Mixed banner"
          style={{ width: "100%" }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: 50 + "%",
            bottom: 50 + "%",
            left: 50 + "%",
            marginTop: 0,
            transform: "translate(-50%, -50%)",
          }}
        >
          <h2
            style={{
              color: "black",
              fontSize: isWindow || window.innerWidth < 448 ? "4vw" : "25px",
              fontFamily:
                // "Inter, system-ui, Avenir, Helvetica, Arial, sans-serif",
                "Helvetica, Arial, sans-serif",
              fontWeight: 500,
              // width: "70%",
            }}
          >
            {" "}
            <em> The chatbot for Hepatitis information. </em>
          </h2>
          <a href="/chat">
            <div className="begin">
              <button
                className="send"
                style={{
                  width: 130,
                  height: 40,
                  backgroundColor: "white", // #E68273
                  color: "black", // #331F20
                  borderRadius: 2,
                }}
              >
                Let's Chat
              </button>
              <button
                className="airplane"
                style={{
                  width: 50,
                  height: 40,
                  backgroundColor: isMouseOver ? "lightgray" : "white", // #B07276
                  borderRadius: 2,
                  marginLeft: -10,
                  color: "black",
                }}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
              >
                <img
                  style={{ height: 25, marginTop: 4 }}
                  alt="let's chat"
                  src="./sendButton_2.png"
                />
              </button>
            </div>
          </a>
        </div>
      </div>
      <div className="bottom">
        <div style={{ height: 30 }}></div> {/*  */}
        <div className="title" style={{ color: "#49b7a8" }}>
          About
        </div>
        <div
          className="thick"
          style={{
            backgroundColor: "rgb(53, 104, 146)",
            height: 1,
          }}
        />
        <p
          style={{
            width: "80%",
            padding: "10px 10px 10px 10px",
            color: "black",
            fontFamily: "Inter,system-ui,Avenir,Helvetica,Arial,sans-serif",
            fontSize: "medium",
            margin: "20px auto auto auto",
          }}
        >
          ChatHep is an AI chatbot designed to answer questions regarding
          hepatitis. It's trained on recent information, backed up by older
          sources from OpenAI's ChatGPT, so that you can easily navigate and
          find information without having to dig through endless pages of links.
          We made this chatbot to spread awareness about hepatitis, aimed mainly
          at younger people and fellow students. We hope that by making this,
          more people can be aware of what to do earlier on. Hepatitis can be
          caused by viruses, such as the ones written below, as well as
          infections, autoimmune diseases, alcohol, and other factors.
        </p>
        {/* hepatitis boxes */}
        <div className="parent">
          <h2 className="h2" style={{ color: "#49b7a8" }}>
            HEPATITIS
          </h2>
        </div>
        <div className="thick" style={{ width: 50, marginTop: 20 }} />
        <div className="parent">
          <p className="p">An inflammation of the liver.</p>
        </div>
        {window.innerWidth < 613 || isWindow ? (
          <div style={{ backgroundColor: "transparent", marginBottom: -20 }}>
            {" "}
            {/* #354F5F */}
            <RightBox
              title="HEPATITIS A"
              message="Contagious infection of the liver caused by hep A virus. It's generally transmitted through contaminated food or water."
              image="./hepA.png"
              alt="hepatitis A"
            />
            <LeftBox
              title="HEPATITIS B"
              message="Serious infection of the liver caused by the hep B virus; there's no cure, but it's preventable by vaccine."
              image="./hepB.png"
              alt="hepatitis B"
            />
            <RightBox
              title="HEPATITIS C"
              message="Attack on the liver from the hep C virus; leads to inflammation of the liver. It's generally transmitted through infected blood."
              image="./hepC.png"
              alt="hepatitis C"
            />
            <LeftBox
              title="HEPATITIS D"
              message="Serious liver disease caused by the Hep D virus; occurs if hep B is also present."
              image="./hepD.png"
              alt="hepatitis D"
            />
            <RightBox
              title="HEPATITIS E"
              message="Liver disease mainly transmitted by contaminated drinking water."
              image="./hepE.png"
              alt="hepatitis E"
            />
            {/* 
        <LeftBox
          title="AUTOIMMUNE HEPATITIS"
          message="Inflammation of the liver due to immune system attacking the liver."
          image="./autoHep.png"
          alt="autoimmune hepatitis" />

        <RightBox
          title="ALCOHOLIC HEPATITIS"
          message="Inflammation of the liver due to heavily drinking alcohol."
          image="./alcoHep.png"
          alt="alcoholic hepatitis" /> */}{" "}
          </div>
        ) : (
          <div style={{ backgroundColor: "transparent", marginBottom: -20 }}>
            <RightBoxTwo
              title="HEPATITIS A"
              message="Contagious infection of the liver caused by hep A virus. It's generally transmitted through contaminated food or water."
              image="./hepA.png"
              alt="hepatitis A"
            />
            <LeftBoxTwo
              title="HEPATITIS B"
              message="Serious infection of the liver caused by the hep B virus; there's no cure, but it's preventable by vaccine."
              image="./hepB.png"
              alt="hepatitis B"
            />
            <RightBoxTwo
              title="HEPATITIS C"
              message="Attack on the liver from the hep C virus; leads to inflammation of the liver. It's generally transmitted through infected blood."
              image="./hepC.png"
              alt="hepatitis C"
            />
            <LeftBoxTwo
              title="HEPATITIS D"
              message="Serious liver disease caused by the Hep D virus; occurs if hep B is also present."
              image="./hepD.png"
              alt="hepatitis D"
            />
            <RightBoxTwo
              title="HEPATITIS E"
              message="Liver disease mainly transmitted by contaminated drinking water."
              image="./hepE.png"
              alt="hepatitis E"
            />{" "}
          </div>
        )}
        <Footer />
        {/* <div style={{ height: 10 }} /> */}
      </div>
    </div>
  );
}

// React Props
// function Card(props) {
//   return <div>
//     <p>{props.name}</p>
//   </div>
// }

// <Card name="Beyonce" />

export default Home;
