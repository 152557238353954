const links = [
  {
    id: 1,
    title: "Asian Liver Center - Stanford University",
    link: "https://med.stanford.edu/liver.html",
    display: "liver.stanford.edu",
  },
  {
    id: 2,
    title: "Hep B Moms",
    link: "https://www.hepbmoms.org/",
    display: "hepbmoms.org",
  },
  {
    id: 3,
    title: "Team HBV",
    link: "http://teamhbv.org/",
    display: "teamhbv.org",
  },
  {
    id: 4,
    title: "APAVH",
    link: "https://stanfordapavh.org/",
    display: "stanfordapavh.org",
  },
  {
    id: 5,
    title: "JoinJade",
    link: "https://joinjade.org/",
    display: "joinjade.org",
  },
  {
    id: 6,
    title: "JoinJade [Chinese]",
    link: "http://www.joinjade.cn/",
    display: "joinjade.cn",
  },
  {
    id: 7,
    title: "Hep B Calculator",
    link: "https://hepbcalculator.org/index.html",
    display: "hepbcalculator.org",
  },
  {
    id: 8,
    title: "Chronic Hepatitis B Treatment Decision Tool For Adults",
    link: "https://nohepb.org/index.html",
    display: "nohepb.org",
  },
  {
    id: 9,
    title: "Hepatitis B Health Risk Assessment",
    link: "http://hepbhra.org/",
    display: "hepbhra.org",
  },
  {
    id: 10,
    title: "CDC",
    link: "https://www.cdc.gov/hepatitis/index.htm",
    display: "cdc.gov/hepatitis.org",
  },
  {
    id: 11,
    title: "WHO",
    link: "https://www.who.int/health-topics/hepatitis",
    display: "who.int/health-topics/hepatitis",
  },
];

export default links;
