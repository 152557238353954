import React from "react";

function Footer() {
  return (
    <footer>
      <div
        style={{
          backgroundColor: "#49b7a8", // #e68273
          height: "fit-content",
          width: "100vw",
          marginLeft: -10,
          paddingTop: 5,
        }}
      >
        <a
          style={{ backgroundColor: "#49b7a8" }}
          href="https://med.stanford.edu/liver.html"
          target="_blank"
          rel="noreferrer"
        >
          <img className="footer-logo" src="./ALC-logo.png" alt="ALC logo" />
        </a>
        <a
          style={{ backgroundColor: "#49b7a8" }}
          href="https://med.stanford.edu/liver.html"
          target="_blank"
          rel="noreferrer"
        >
          <p style={{ paddingBottom: 10, color: "rgb(177, 248, 239)" }}>
            {" "}
            {/* black */}© Asian Liver Center at Stanford University | 2023
          </p>
        </a>
      </div>
    </footer>
  );
}

export default Footer;
