import React from "react";
import Header from "./NavHead";
import "./Resources.css";
import ShowLinks from "./showLinks";
import links from "./links";
import Footer from "./footer";

function Resources() {
  function createLink(oneLink, index) {
    return (
      <ShowLinks
        key={index}
        title={oneLink.title}
        link={oneLink.link}
        display={oneLink.display}
      />
    );
  }

  return (
    <div
      style={{
        backgroundColor: "rgb(243, 243, 243)",
      }}
    >
      <Header chat="#331F20" use="#331F20" resources="white" three="650" />
      <div className="title" style={{ fontSize: "30px", color: "#49b7a8" }}>
        Additional Links
      </div>
      <div
        className="thick"
        style={{
          width: "80%",
          backgroundColor: "rgb(53, 104, 146)",
          height: 1,
        }}
      />
      <div>{links.map(createLink)}</div>
      <div style={{ height: "1px" }} />
      {/* PDFs too? */}
      <Footer />
    </div>
  );
}

export default Resources;
