import React from "react";
import "./Resources.css";

function ShowLinks(props) {
  return (
    <div style={{ marginRight: 50 }}>
      <div className="mother">
        <div className="resource" style={{ color: "black" }}>
          {props.title}
        </div>
        <a
          style={{ backgroundColor: "transparent", color: "#E0E0E0" }}
          href={props.link}
          target="_blank"
          rel="noreferrer"
        >
          <div
            className="resource"
            style={{
              fontSize: "large",
              fontWeight: 300,
              color: "black",
            }}
          >
            <u>{props.display}</u>
          </div>
        </a>
      </div>
    </div>
  );
}

export default ShowLinks;
