import React from "react";
import "./InfoBox.css";

function LeftBox(props) {
  return (
    <div
      className="parent1"
      style={{
        flexDirection: "column-reverse",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p
          style={{
            backgroundColor: "transparent",
            borderRadius: "40px",
            color: "black", // #e0e0e0
            width: 90 + "%",
            minWidth: 100,
            maxWidth: 400,
            minHeight: 60,
            marginRight: -15,
            padding: "10px 30px 10px 25px",
            fontFamily: "Inter,system-ui,Avenir,Helvetica,Arial,sans-serif",
            fontSize: "medium",
          }}
        >
          {props.message}
        </p>
        <img
          style={{
            width: 200,
            border: 0,
            marginLeft: -30,
          }}
          alt={props.alt}
          src={props.image}
        ></img>
      </div>
      {/* <div className="thin"></div> */}
      <div className="thick" style={{ width: 50 }} />
      <h2
        className="h2"
        style={{
          width: "90%",
          alignSelf: "center",
          color: "#49b7a8",
        }}
      >
        {props.title}
      </h2>
    </div>
  );
}

function RightBox(props) {
  return (
    <div
      className="parent2"
      style={{
        margin: "auto",
        flexDirection: "column",
      }}
    >
      <h2
        className="h2"
        style={{
          width: "90%",
          alignSelf: "center",
          marginBottom: -30,
          color: "#49b7a8",
        }}
      >
        {props.title}
      </h2>
      <div className="thick" style={{ width: 50 }} />
      {/* <div className="thin"></div> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          alignItems: "center",
        }}
      >
        <img
          style={{
            zIndex: 9999,
            marginRight: -15,
            width: 200,
            border: 0,
          }}
          alt={props.alt}
          src={props.image}
        ></img>
        <p
          style={{
            backgroundColor: "transparent",
            borderRadius: "40px",
            color: "black",
            width: 90 + "%",
            minWidth: 100,
            maxWidth: 400,
            minHeight: 60,
            padding: "10px 15px 10px 30px",
            fontFamily: "Inter,system-ui,Avenir,Helvetica,Arial,sans-serif",
            fontSize: "medium",
          }}
        >
          {props.message}
        </p>
      </div>
    </div>
  );
}

export { LeftBox, RightBox };
