import React, { useState, useEffect } from "react";
import "./InfoBox.css";

function LeftBoxTwo(props) {
  const [isWindow, setWindow] = useState(
    window.matchMedia("(max-width: 800px)").matches
  );

  useEffect(() => {
    function handleResize() {
      window.innerWidth < 800 ? setWindow(true) : setWindow(false);
    }

    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });
  return (
    <div
      className="parent1"
      style={{
        flexDirection: "row",
        marginLeft: "5%",
        justifyContent:
          isWindow || window.innerWidth < 800 ? "center" : "flex-start",
      }}
    >
      {isWindow && (
        <img
          style={{
            width: 100,
            border: 0,
            marginLeft: -30,
            marginTop: 30,
            alignSelf: "center",
          }}
          alt={props.alt}
          src={props.image}
        ></img>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 10,
        }}
      >
        <h2
          className="h2"
          style={{
            alignSelf: "flex-start",
            color: "#49b7a8",
          }}
        >
          {props.title}
        </h2>
        <div className="thick" style={{ width: 40 }} />
        <div
          style={{
            backgroundColor: "transparent",
            borderRadius: "40px",
            color: "black",
            width: 90 + "%",
            minWidth: 100,
            maxWidth: 400,
            minHeight: 60,
            padding: "10px 30px 10px 25px",
            fontFamily: "Inter,system-ui,Avenir,Helvetica,Arial,sans-serif",
            fontSize: "medium",
          }}
        >
          {props.message}
        </div>
      </div>
      {/* <div className="thin"></div> */}
      {!isWindow && (
        <img
          style={{
            width: 100,
            border: 0,
            marginLeft: -30,
            marginTop: 30,
            alignSelf: "center",
          }}
          alt={props.alt}
          src={props.image}
        ></img>
      )}
    </div>
  );
}

function RightBoxTwo(props) {
  const [isWindow, setWindow] = useState(
    window.matchMedia("(max-width: 800px)").matches
  );

  useEffect(() => {
    function handleResize() {
      window.innerWidth < 800 ? setWindow(true) : setWindow(false);
    }

    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });
  return (
    <div
      className="parent2"
      style={{
        flexDirection: "row",
        marginRight: "5%",
        justifyContent:
          isWindow || window.innerWidth < 800 ? "center" : "flex-end",
      }}
    >
      <img
        style={{
          zIndex: 9999,
          marginRight: -45,
          width: 100,
          border: 0,
          alignSelf: "center",
          marginTop: 30,
        }}
        alt={props.alt}
        src={props.image}
      ></img>
      {/* {console.log(window.innerWidth)} */}
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        <h2
          className="h2"
          style={{
            alignSelf: isWindow ? "flex-start" : "flex-end",
            color: "#49b7a8",
          }}
        >
          {props.title}
        </h2>
        <div className="thick" style={{ width: 40 }} />
        {/* <div className="thin"></div> */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "transparent",
              borderRadius: "40px",
              color: "black",
              width: 90 + "%",
              minWidth: 100,
              maxWidth: 400,
              minHeight: 60,
              padding: "10px 15px 10px 30px",
              fontFamily: "Inter,system-ui,Avenir,Helvetica,Arial,sans-serif",
              fontSize: "medium",
            }}
          >
            {props.message}
          </div>
        </div>
      </div>
    </div>
  );
}

export { LeftBoxTwo, RightBoxTwo };
