const conversationList = [
  {
    id: 1,
    message: "Go to chat",
  },
  {
    id: 2,
    message: "Type in a question about hepatitis into chat",
  },
  {
    id: 3,
    message: "Hit enter or click send",
  },
  {
    id: 4,
    message: "Receive your answer from chatHep",
  },
];

export default conversationList;
