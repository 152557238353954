import React from "react";
import "./Use.css";
import Header from "./NavHead";
import Block from "./Block";
import conversationList from "./conversations";
import Footer from "./footer";

function Use() {
  function createBlock(oneBlock, index) {
    return (
      <Block
        id={oneBlock.id}
        key={index}
        idx={index}
        message={oneBlock.message}
      />
    );
  }

  return (
    <div>
      <div className="use-height">
        <div
          style={{
            backgroundColor: "rgb(243, 243, 243)", //#354F5F
            zIndex: -3,
            position: "fixed",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
          }}
        />
        <Header chat="#331F20" use="white" resources="#331F20" two="650" />
        <div
          style={{
            zIndex: -1,
            backgroundColor: "#e0e0e0",
            position: "fixed",
            width: 25 + "%",
            top: 0,
            bottom: 0,
            left: 0,
          }}
        />
        <div
          style={{
            zIndex: -1,
            backgroundColor: "#e0e0e0",
            position: "fixed",
            width: 25 + "%",
            top: 0,
            bottom: 0,
            right: 0,
          }}
        />
        <div>{conversationList.map(createBlock)}</div>
      </div>
      <Footer />
    </div>
  );
}

export default Use;
